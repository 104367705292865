<template>
    <div class="setting-app">
        <div style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: start; padding-bottom: 20px; border-bottom: 1px solid #5f5a5a" v-for="item in deskTopAppList" :key="item.key">
            <div class="app-item" v-if="!item.hideInDesktop">
                <div class="icon">
                    <i
                        v-if="item?.icon"
                        :style="{
                            color: item.iconColor,
                            'font-size': '36px'
                        }"
                        class="iconfont"
                        :class="item.icon"
                    ></i>
                    <img style="width: 36px" v-else :src="item.logo" />
                </div>
                <div class="title">{{ item.title }}</div>
            </div>

            <div class="item" style="width: 100%; display: flex" v-if="!item.hideInDesktop">
                <input placeholder="请输入完整的服务器地址" type="text" v-model="urlModel[item.key]" />

                <span style="width: 50px; color: #00ac97; cursor: pointer" @click="setWebUrl(item.key)">确定</span>
            </div>
        </div>
    </div>
    <span style="padding: 5px; font-size: 12px; color: #333">请配置完整的服务器地址，包括：协议，主机名，端口，路径等</span>
</template>

<script>
export default {
    data() {
        return {
            deskTopAppList: [],
            urlModel: {}
        };
    },
    methods: {
        // 设置服务器地址
        setWebUrl(key) {
            localStorage.setItem(key, this.urlModel[key]);
            this.$message.success('配置成功');

            // const urlPattern =
            //     /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;

            // const match = this.urlModel[key].match(urlPattern);

            // if (match && (this.urlModel[key].startsWith('http://') || this.urlModel[key].startsWith('https://'))) {
            //     localStorage.setItem(key, this.urlModel[key]);
            //     this.$message.success('配置成功');
            // } else {
            //     this.$message.warning('请配置正确的服务器地址');
            // }
        }
    },
    created() {
        const deskTopAppList = this.tool.getDeskTopApp();

        // 过滤隐藏的app
        this.deskTopAppList = deskTopAppList.filter((item) => item.hideInDesktop !== true);

        this.deskTopAppList.forEach((item) => {
            if (item.key.startsWith('sd_')) {
                // 优先读取缓存
                const value = localStorage.getItem(item.key);

                if (value) {
                    this.urlModel[item.key] = value;
                } else {
                    this.urlModel[item.key] = item.url;
                }
            }
        });
    }
};
</script>

<style scoped lang="scss">
.setting-app {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    flex-wrap: wrap-reverse;
    background: rgb(0 0 0 / 90%);
    .app-item {
        display: flex;
        padding: 10px 20px;
        flex-direction: row;
        text-align: center;
        text-shadow: 0px 0px 2px rgb(0 0 0 / 50%);
        border-radius: 10px;
        border: 2px solid transparent;
        justify-content: center;
        align-items: center;
        .icon {
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
        }
        .iconfont {
            font-size: 28px;
            border-radius: 10px;
        }
        .title {
            font-size: 14px;
            margin-left: 5px;
            white-space: nowrap;
        }
    }

    .item {
        vertical-align: middle;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
        input {
            flex: 1;
            color: white;
            outline: none;
            border: none;
            margin: 5px;
            font-size: 16px;
            background-color: rgba(255, 255, 255, 0.3);
            padding: 8px 24px;
            border-radius: 20px;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
            width: 100%;
        }

        input::placeholder {
            color: #e3e3e3;
        }

        .iconfont {
            vertical-align: middle;
            display: inline-block;
            background-color: rgba(255, 255, 255, 0.3);
            font-size: 18px;
            border-radius: 100%;
            width: 36px;
            height: 36px;
            text-align: center;
            line-height: 36px;
            cursor: pointer;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
        }
        .iconfont:hover {
            background-color: rgba(255, 255, 255, 0.5);
        }
        .click-enable {
            right: 0;
        }
    }
}
</style>
